import { FiX, FiSun, FiMoon } from "react-icons/fi"
import useDarkMode from "../hooks/useDarkMode"
import {IoShareOutline} from 'react-icons/io5'
import {FaLocationArrow } from "react-icons/fa"
import {MdCategory} from 'react-icons/md'
import {useState} from 'react'
import Modal from "./modal"
import data, { sideNavData } from "../data"

export default function SideNav({ sideNav, setSideNav, swiper }) {
  const [isDarkMode, toggleDarkMode] = useDarkMode()
  const [modal1On, setModal1On] = useState(false)
  const [modal2On, setModal2On] = useState(false)

  const share =  event => {
    if (navigator.share) {
      navigator.share({
        text: 'منيو دبليو فايف : ',
        url: 'https://w5-lounge.online'
      }).then(() => {
        console.log('! شكراً على المشاركة');
      })
        .catch((err) => console.error(err));
    } else {
      alert("جهازك الحالي لا يدعم خاصية المشاركة")
    }
  }

  const toggleTheme = () => {
    if (isDarkMode) {
      return (
        <div
          onClick={() => toggleDarkMode()}
          className="relative flex justify-between items-center py-3 px-4 rounded-br-md rounded-bl-md transition bg-gray-100 bg-opacity-500 text-gray-800 cursor-pointer"
        >
          <h1 className="text-xs md:text-md text-gray-900 font-semibold">
            وضع نهاري
          </h1>
          <div className="flex items-center p-1 rounded-full bg-white text-gray-800">
            <FiSun className="w-5 h-5" />
          </div>
        </div>
      )
    } else {
      return (
        <div
          onClick={() => toggleDarkMode()}
          className="relative flex justify-between items-center py-2.5 px-4 rounded-br-md rounded-bl-md transition bg-gray-100 bg-opacity-500 text-gray-800 cursor-pointer"
        >
          <h1 className="text-xs md:text-md text-gray-900 font-semibold">
            وضع ليلي
          </h1>
          <div className="flex items-center p-1 rounded-full bg-gray-700 text-gray-100">
            <FiMoon className="w-5 h-5" />
          </div>
        </div>
      )
    }
  }

  return (
    <div className="relative inset-0 flex justify-center z-[102]">
      <div
        className={`fixed inset-0 bg-black dark:bg-white bg-opacity-70 dark:bg-opacity-70 ${
          sideNav ? "block" : "hidden"
        }`}
        onClick={() => setSideNav(false)}
      ></div>
      <div
        className={`fixed w-[85%] top-0 right-0 bg-white flex flex-col dark:bg-gray-700 h-full transition-all duration-500 ease-out rounded-sid  ${
          sideNav ? "left-[15%]" : "left-[100vw]"
        }`}
      >
        <FiX
          className={`eax absolute left-1.5 top-1.5 z-103 w-5 h-5 p-0.5 mx-2 rounded-full bg-primaryGreen-500 text-gray-50 hover:bg-gray-400 hover:bg-opacity-50 transform hover:rotate-180 dark:bg-primaryGreen-500 dark:text-gray-50 dark:hover:bg-gray-400 ${
            sideNav ? "block" : "hidden"
          }`}
          onClick={() => setSideNav(false)}
        />
        <div
          className={`bg-primaryGreen-300 w-full dark:bg-white flex items-center justify-center py-2`}
        >
          <img
            src="../../img/logosid.png"
            alt="side-logo"
            className="w-[150] h-[120px]"
          />
        </div>
        <div
          className={`flex flex-col w-full overflow-hidden px-2 mt-2 gap-0.5`}
          onClick={() => setSideNav(false)}
        >
          <p className="py-2.5 px-4 rounded-tr-md rounded-tl-md bg-gray-100 bg-opacity-500 text-justify text-xs leading-normal text-gray-900 font-semibold cursor-pointer">
            يحتاج البالغون إلى 2000 سعره حرارية في المتوسط يومياً، وقد تختلف
            الاحتياجات الفردية من السعرات الحرارية من شخص لآخر البيانات التغذوية
            الإضافية متاحة عند الطلب .
          </p>
          {sideNavData.map((link) => (
            <a
              href={link.href}
              className="relative flex justify-between items-center py-2.5 px-4 rounded-sm transition bg-gray-100 bg-opacity-500 text-gray-800"
              key={link.id}
            >
              <h1 className="text-xs md:text-md text-gray-900 font-semibold">
                {link.text}
              </h1>
              <link.icon className="text-primaryGreen-500 w-5 h-5" />
            </a>
          ))}
        <button
              onClick={(e)=>share(e)}
              className="relative flex justify-between items-center py-2.5 px-4 rounded-sm transition bg-gray-100 bg-opacity-500 text-gray-800"
            >
              <h1 className="text-xs md:text-md text-gray-900 font-semibold">
               مشاركة
              </h1>
        <IoShareOutline className="text-primaryGreen-500 w-5 h-5" />
            </button>
            <button
            onClick={() => setModal1On(true)}
            className="relative flex justify-between items-center py-2.5 px-4 rounded-sm transition bg-gray-100 bg-opacity-500 text-gray-800"
          >
            <h1 className="text-xs md:text-md text-gray-900 font-semibold">
              اللوكيشن
            </h1>
            <FaLocationArrow className="text-primaryGreen-500 w-5 h-5" />
          </button>
          <button
            onClick={() => setModal2On(true)}
            className="relative flex justify-between items-center py-2.5 px-4 rounded-sm transition bg-gray-100 bg-opacity-500 text-gray-800"
          >
            <h1 className="text-xs md:text-md text-gray-900 font-semibold">
              التصنيفات
            </h1>
            <MdCategory className="text-primaryGreen-500 w-5 h-5" />
          </button>
             {modal1On && (
            <Modal title="خريطة جوجل" setModalOn={setModal1On}>
            <iframe title="العنوان"
                src="https://maps.google.com/maps?width=600&amp;height=600&amp;hl=en&amp;q=W5 LOUNGE مطعم وكافيه W5&amp;t=&amp;z=19&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                className="w-full h-[400px] my-4 shadow-md"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
              <a
                href="https://maps.app.goo.gl/C2T8pUECoME5akgQA"
                className="py-2 px-10 rounded-full bg-primaryGreen-500 text-white outline-none w-fit mx-auto"
              >
                إذهب إلى الخريطة
              </a>
            </Modal>
          )}

          {modal2On && (
            <Modal title="التصنيفات" setModalOn={setModal2On}>
              <div className="flex flex-col gap-2 my-4 overflow-y-auto overflow-x-hidden px-5 py-4">
                {data.map((category, i) => (
                  <button
                    onClick={() => {
                      setModal2On(false)
                      console.log(category.id)
                      swiper.slideTo(category.id)
                    }}
                    className="py-2 px-4 rounded-lg w-full flex items-center justify-between bg-gray-100 text-gray-900 cursor-pointer hover:bg-gray-200 hover:scale-105 transition-all dark:bg-gray-700 dark:text-white"
                    key={i}
                  >
                    <h5 className="font-semibold text-lg">{category.title}</h5>
                    <p className="w-9 h-9 p-1.5 rounded-full bg-primaryGreen-500 text-white flex items-center justify-center">
                      {category.items.length}
                    </p>
                  </button>
                ))}
              </div>
            </Modal>
          )}
          {toggleTheme()}
        </div>
      </div>
    </div>
  )
}
