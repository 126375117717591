import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import { Pagination } from "swiper"
import Item from "./Item"

const Items = ({
  pagination,
  close,
  categories,
  isSubmitted,
  filteredItems,
  setSwiper
}) => {
  return (
    <Swiper
      loop={true}
      autoHeight={true}
      pagination={pagination}
      speed={500}
      modules={[Pagination]}
      onSwiper={(swiper) => setSwiper(swiper)}
      onSlideChange={() => {
        const activeCat = document.querySelector(
          ".swiper-pagination-bullet-active"
        )
         document.documentElement.scrollTop = 0; 
        activeCat?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        })
        close()
      }}
    >
      {categories.map((category, i) => (
        <SwiperSlide key={i}>
            <div className='relative space-y-2 flex flex-col justify-center overflow-hidden items-center animateItems'>
              <div className='w-full p-4 flex flex-col gap-2 items-center bg-white dark:bg-gray-700'>
                {isSubmitted ? (
                  filteredItems.length === 0 ? (
                    <div className='w-full flex items-center font-semibold gap-4 justify-center flex-col my-5 dark:text-white'>
                      <img
                        src='../../img/delete.png'
                        alt='not-found'
                        width='100em'
                        height='100em'
                      />
                      <h2 className='text-3xl text-center text-primaryGreen-500 dark:text-white'>
                        لا يوجد نتائج
                      </h2>
                    </div>
                ) : (
                  filteredItems.map((item, index) => (
                    <Item item={item} key={index} />
                  ))
                )
              ) : (
                category.items?.map((item, index) => (
                  <Item item={item} key={index} />
                ))
              )}
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default Items
