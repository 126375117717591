import { AiOutlineClose } from "react-icons/ai"

const Modal = ({ children, title, setModalOn }) => {
  const handleClose = (e) => {
    if (e.target.id === "container") setModalOn(false)
  }
  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black z-50 backdrop-blur-sm bg-opacity-25 !m-0"
      id="container"
      onClick={handleClose}
    >
      <div className="w-[95%] scrollbar max-h-[600px] p-4 rounded-lg md:w-[500px] mx-auto bg-white flex flex-col">
        <div className="flex justify-between items-center border-b border-gray-300 pb-2">
          <h4 className="font-semibold text-lg">{title}</h4>
          <button>
            <AiOutlineClose
              className="eax2 w-7 h-7 p-0.5 rounded-full bg-primaryGreen-500 text-gray-50 hover:bg-gray-400 hover:bg-opacity-50 transform hover:rotate-180 dark:bg-primaryGreen-500 dark:text-gray-50 dark:hover:bg-gray-400"
              onClick={() => setModalOn(false)}
            />
          </button>
        </div>
        {children}
      </div>
    </div>
  )
}

export default Modal
