import TopNav from "./components/TopNav"
import SideNav from "./components/SideNav"
import { useRef, useState } from "react"
import categories from "./data"
import SearchBar from "./components/searchBar"
import SearchLabel from "./components/searchLabel"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Items from "./components/items"
import InstallPrompt from "./components/InstallPrompt"

function App() {
  const inputRef = useRef()

  const [sideNav, setSideNav] = useState(false)
  const [filteredItems, setFilteredItems] = useState([])
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [searchValue, setSearchValue] = useState(inputRef.current?.value || "")
  const [searchQuery, setSearchQuery] = useState('')
  const [swiper, setSwiper] = useState()

  const handleSearch = (e) => {
    e.preventDefault()
    setSearchQuery(e.target[0].value)

    if (searchQuery.length > 0) {
      setIsSubmitted(true)
      setSearchValue(e.target[0].value)
      const items = categories
        .flatMap((c) => c.items)
        .filter((i) => i.title.includes(e.target[0].value))

      setFilteredItems(items)
      setSearchQuery('')
      e.target[0].blur()
      console.log(e)
    }
    e.target[0].blur()
     document.documentElement.scrollTop = 0;
  }

  const close = () => {
    setIsSubmitted(false)
    setFilteredItems([])
  }

  const pagination = {
    el: ".my-custom-pagination",
    clickable: true,
    renderBullet: (index, className) => {
      return `
        <span class="${className}">
          <span className="text-xs whitespace-nowrap font-semibold">
            ${categories[index]?.title}
          </span>
          <Image
            src=${categories[index]?.image}
            alt=""
            width="24"
            height="24"
            priority
            className='z-10'
          />
        </span>
      `
    },
  }

  return (
      <div
          className="relative min-h-screen max-w-md mx-auto shadow-2xl transition duration-100 dark:bg-gray-700"
          dir="rtl"
        >
        
        <Router>
            <TopNav setSideNav={setSideNav} />
            <SearchBar
              handleSearch={handleSearch}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
            {/* Pagination */}
            <div className="my-custom-pagination"></div>
            {isSubmitted && (
              <SearchLabel
                filteredItems={filteredItems}
                searchValue={searchValue}
                close={close}
              />
            )}
            <SideNav sideNav={sideNav} setSideNav={setSideNav} swiper={swiper} />
            <Routes>
              <Route
                path="/"
                element={
                  <Items
                    pagination={pagination}
                    close={close}
                    categories={categories}
                    isSubmitted={isSubmitted}
                    filteredItems={filteredItems}
                    setSwiper={setSwiper}
                  />
                }
              />
            </Routes>
        </Router>
        <InstallPrompt />
      </div>
  )
}

export default App
